<template>
  <container ref="service" @error="init">
    <!-- 服务介绍 -->
    <publicSection
      class="serviceIntroduction"
      :header="require('@/assets/image/public/serviceIntroduction.png')"
    >
      <div class="serviceIntroduction-content" v-html="service.content"></div>
    </publicSection>

    <!-- 视频 -->
    <publicSection
      class="video"
      :header="require('@/assets/image/public/videoIntroduction.png')"
    >
      <!-- 视频-列表 -->
      <div class="video-list">
        <!-- 视频-卡片 -->
        <video-card
          class="video-card"
          :detail="{ src: service.video1, cover: service.videopic1 }"
        />
        <video-card
          class="video-card"
          :detail="{ src: service.video2, cover: service.videopic2 }"
        />
      </div>
    </publicSection>
  </container>
</template>

<script>
import publicSection from "@/components/publicSection.vue";
import videoCard from "@/components/video-card.vue";

export default {
  name: "postpartumService",
  components: {
    publicSection,
    videoCard,
  },
  data() {
    return {
      service: {},
    };
  },
  mounted() {
    // 初始化
    this.init();
  },
  methods: {
    /**
     * 初始化数据
     */
    async init() {
      try {
        // 设置状态
        this.$refs.service.status("init", {
          height: "100vh",
        });

        // 获取数据
        const service = await this.$axios(
          "/api/mainstation/watchService?type=3"
        );

        // 设置数据
        this.service = service.data;

        // 设置状态
        this.$refs.service.status("default");
      } catch (e) {
        // 设置状态
        this.$refs.service.status("error", {
          text: e.message,
          height: "100vh",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  // 服务介绍
  .serviceIntroduction {
    margin-top: 50px;

    // 服务介绍-内容
    &-content {
      display: block;
      width: 1200px;
      margin: auto;
    }
  }

  // 视频
  .video {
    padding-top: 20px;
    padding-bottom: 70px;
    width: 1200px;
    margin: auto;

    // 视频-列表
    &-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 40px;
    }

    // 视频-元素
    &-card {
      margin-right: 20px;

      &:nth-of-type(2) {
        margin-right: 0;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  // 服务介绍
  .serviceIntroduction {
    margin-top: rpx(40);

    // 服务介绍-内容
    &-content {
      display: block;
      width: 100%;
      margin: auto;
    }
  }

  // 视频
  .video {
    padding-top: rpx(30);
    padding-bottom: rpx(50);
    width: 100%;
    margin: auto;

    // 视频-列表
    &-list {
      display: flex;
      flex-wrap: wrap;
      padding: rpx(40);
    }

    // 视频-元素
    &-card {
      margin-bottom: rpx(30);
    }
  }
}
</style>